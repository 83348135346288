@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;

@tailwind components;

@tailwind utilities;

body {
  @apply bg-[#111];
}
.react-tabs__tab{
  @apply font-press-start font-bold
}
.react-tabs__tab-list{
  border-bottom:none !important;

}
.react-tabs__tab--selected{
  border-bottom:none !important;
}
.react-tabs__tab--selected::after{
  height:0 !important;
  width:0 !important;
}
.react-tabs__tab , .react-tabs__tab--selected{
  border-bottom:0px solid transparent !important;
}

@media (max-width: 500px) {
  .bm-menu-wrap {
    left: 70px !important;
  }
}

@media (max-width: 992px) {
  .react-tabs__tab-list {
    display: flex;
    justify-content:center;
  }
}
@media (max-width: 400px) {
  .react-tabs__tab-list {
    display: flex;
    flex-direction:column;
    align-items:flex-end;
    padding-right:10px !important;
  }
}

@media (max-width: 500px) {
  .bm-menu {
    padding-left: 10px !important;
    width: 240px !important;
  }
}

.Menu__Link{
  @apply text-white font-bold font-press-start cursor-pointer 
}
.Menu__Link__Menu{
  @apply text-white  md:text-5xl font-bold font-press-start cursor-pointer transition-all duration-300 ease-linear hover:blur-[2px]
}

#NavMenu{
  @apply border-t-2  box-border h-full absolute overflow-hidden bg-[#111] left-[60px] sm:left-[80px] transition-all duration-500 
}

.menuActive{
  @apply w-[200px]  sm:w-[40vw]  left-[50px] border-r-2 z-50 relative
}
.menu{
  @apply w-0 relative z-50
}
